import React from "react"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Footer from "../components/footer"
import { Link, graphql } from "gatsby"
import PubCategories from "../components/publicaciones-categories"

export const query = graphql`
  {
    publicaciones: allDatoCmsPublicacion(
      sort: { fields: [publicadoEn], order: DESC }
      filter: {
        autor: { autor: { eq: "Aldo Milano" } }
        categoria: { categoria: { eq: "Revistas" } }
      }
    ) {
      nodes {
        titulo
        slug
        resumen
        autor {
          autor
        }
        categoria {
          categoria
        }
        publicadoEn(formatString: "DD/MM/YYYY")
      }
    }
  }
`

const PublicacionesPage = ({ data }) => (
  <Layout pageTitle="Publicaciones en Revistas">
    <div className="w-full mx-auto  bg-gray-200 border-b border-gray-200">
      <div className="w-full lg:w-10/12 mx-auto">
        <Navigation />
      </div>
    </div>
    <div className="w-full px-5 lg:px-0 lg:w-10/12 mx-auto">
      <div className="w-full mx-auto mt-10 lg:mt-20">
        <h2 className="text-3xl mt-10 lg:mt-0">Publicaciones</h2>

        <PubCategories />

        <div>
          {data.publicaciones.nodes.map(publicacion => (
            <div
              className="post-preview mt-8 mb-20"
              key={`publicacion-${publicacion.slug}`}
            >
              <h2 className="text-xl">{publicacion.titulo}</h2>
              <div
                className="home-body"
                dangerouslySetInnerHTML={{
                  __html: publicacion.resumen
                }}
              ></div>

              <p className="mt-5 text-sm text-gray-800">
                <span className="block">
                  {publicacion.publicadoEn ? (
                    `Del: ${publicacion.publicadoEn}`
                  ) : (
                    <p></p>
                  )}
                </span>
                <span className="block">
                  {publicacion.categoria.categoria ? (
                    `Categoría: ${publicacion.categoria.categoria}`
                  ) : (
                    <p></p>
                  )}
                </span>
              </p>

              <Link
                to={`/${publicacion.slug}`}
                className="inline-block mt-8 py-3 px-5 bg-gray-200 text-sm hover:bg-gray-900 hover:text-gray-100 montserrat transition duration-300 ease-in "
              >
                Leer publicación
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default PublicacionesPage
